
import {FC, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {getSettings} from '../../store/settings/selectors'
import {SettingsI} from '../../store/settings/types'
// import {getUser} from '../../store/user/selectors'
// import {UserI} from '../../store/user/types'
import { Link } from 'react-router-dom'

import { URLS } from '../../resources/constants'

const SideNavbar: FC = () => {
    const today = new Date();
    const year = today.getFullYear();

    const SettingsData = useSelector(getSettings);
    // const UserData = useSelector(getUser)

    const [settingsData, setSettingsData] = useState<SettingsI | null>(null)
    // const [userData, setUserData] = useState<UserI | null>(null)

    useEffect(() => {
        setSettingsData(SettingsData)
    }, [SettingsData])
    // useEffect(() => {
    //     setUserData(UserData)
    // }, [UserData])

    return (
        <div className="left-bar-wrap">
        <ul>
            <li>
                <Link to={URLS.USERPROFILE} className="active"><img src="assets/images/company-profile-icon.svg" alt="" /> Company Profile</Link>
            </li>
            <li>
                <Link to="#"><img src="assets/images/billing-icon.svg" alt="" /> Billing & Subscription</Link>
            </li>
            <li>
                <Link to="#"><img src="assets/images/usage-icon.svg" alt="" /> Usage</Link>
            </li>
            <li>
                <Link to="#"><img src="assets/images/revrex-checking-icon.svg" alt="" /> RevRex Checking</Link>
            </li>
            <li>
                <Link to="#"><img src="assets/images/sales-icon.svg" alt="" /> Sales</Link>
            </li>
            <li>
                <Link to="#"><img src="assets/images/expenses-icon.svg" alt="" /> Expenses</Link>
            </li>
            <li>
                <Link to="#"><img src="assets/images/payments-icon.svg" alt="" /> Payments</Link>
            </li>
            <li>
                <Link to={URLS.SETTINGS} className=""><img src="assets/images/settings-icon.svg" alt="" /> Settings</Link>
            </li>
        </ul>
    </div>
        
    )
}

export default SideNavbar;