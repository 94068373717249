import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getFirstLoginStatus } from '../../store/user/selectors'

export function userSteps() {

  const userStrpStatus = useSelector(getFirstLoginStatus);

  const userStepsChecker = () => {
    return userStrpStatus;
  }
  const [userStepsDetails, setUserStepsDetails] = useState(userStepsChecker())

  useEffect(() => {
    setUserStepsDetails(userStepsChecker())
  }, [userStrpStatus]);
  return userStepsDetails
}