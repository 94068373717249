
import { call, put } from "redux-saga/effects";
import { updateUser, updateBranch, authenticateUser,updateAccessToken, updateMappedAcc, updateFirstLogin } from "../store/user";
import { API_URLS } from "../resources/constants";
import {CallApi} from './callApi'
import { formQueryString } from "../resources/functions";

/*export function* login(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.POST, API_URLS.USER.LOGIN, payload);
    if ( result && result.data.error === false ) {
      yield put(authenticateUser(true));
      //yield put(updateUser(result.data));
      yield put(updateAccessToken(result.data.accessToken));
      action && action.callbackSuccess(result);

    } else {
      //yield put(updateUser({}));
      //yield put(authenticateUser(false));
      //yield put(updateAccessToken(null));
      action && action.callbackError(new Error('Unauthorized Access'));
    }
  } catch (e) {
    //yield put(updateUser({}));
    //yield put(authenticateUser(false));
    //yield put(updateAccessToken(null));
    action && action.callbackError(e);
  }
}*/

export function* me(action: any): any {
  try {
    let result = yield call(CallApi.GET, API_URLS.USER.DETAILS,{},true);
    yield put(updateUser(result.data));
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* user_access(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.USER.USER_ACCESS + '/' + payload.token
    let result = yield call(CallApi.POST, url,{},false);
    action && action.callbackSuccess(result);
    if ( result && result.data.success === true ) {
      yield put(authenticateUser(true));
      yield put(updateAccessToken(result.data.data.token));
      yield put(updateUser(result.data.data.user))
      yield put(updateBranch(result.data.data.branch_details))
      action && action.callbackSuccess(result);

    } else {
      action && action.callbackError(new Error('Unauthorized Access'));
    }
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* user_allowance(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.USER.ACCOUNT_ALLOWANCE + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET , url,{},true);
    action && action.callbackSuccess(result);
    if ( result && result.data.success === true ) {
      yield put(updateMappedAcc(result.data.data));
      action && action.callbackSuccess(result);

    } else {
      action && action.callbackError(new Error('Unauthorized Access'));
    }
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* user_step_status(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.USER.USER_STEP_STATUS + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    // action && action.callbackSuccess(result);
    if ( result && result.data.success === true ) {
      if(result.data.data == null)
      {
        yield put(updateFirstLogin(0));
      }
      else
      {
        yield put(updateFirstLogin(result.data.data));
      }
      action && action.callbackSuccess(result);

    } else {
      action && action.callbackError(new Error('Unauthorized Access'));
    }
  } catch (e) {
    action && action.callbackError(e)
  }
}


