import React, { useEffect, useState, useRef, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, useParams, useNavigate, Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { useForm, Controller, useFormContext, useFieldArray } from "react-hook-form";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import toast from 'react-hot-toast';

import { currencyFormat } from '../../../resources/helper'
import "react-datepicker/dist/react-datepicker.css";
import { RevenueGlClassificationFormI, RevenueGlClassificationSingleFormI } from '../../../resources/form-props';
import { BankSearchFormI } from '../../../resources/form-props';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import { glClassificationApi } from '../../../resources/hooks/api/glClassificationApi';
import { GLSearchFormValidate, BankRevenueValidate } from '../../../resources/form-validator';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useChartAccountApi } from '../../../resources/hooks/api/chartAccountApiHook';
import { getBranch } from '../../../store/user/selectors'

import '../GlClassification.css'
import { GL_VP, SELECT_CUSTOM_STYLE, URLS } from '../../../resources/constants';
import { lastYears, calculateMaxHeightContent } from '../../../resources/functions';
import SelectCustomOption from '../../../components/SelectCustomOption';

interface Props {

}

const RevenueGlClassification: React.FC<Props> = () => {

    const { glTransactionlist, revenueGlUpdate } = glClassificationApi();
    const { glAccountsList } = useChartAccountApi();
    const urlParams = useParams();
    const navigate = useNavigate();
    const branchData = useSelector(getBranch)

    const [records, setRecords] = useState<any>([]);
    //const [glAccount, setGlAccount] = useState<any[]>([]);
    const [glDebitAccount, setGlDebitAccount] = useState<any[]>([]);
    const [glCreditAccount, setGlCreditAccount] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0)
    const [selectedCalendar, setSelectedCalendar] = useState<any>({
        year: 2024,
        month: 1
    });
    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#d1ffbd'));
    const [needFormAttention, setNeedFormAttention] = useState<boolean>(true);
    const checkAllRef = useRef<HTMLInputElement>(null);

    const { register, control, trigger, handleSubmit, reset, setFocus, setValue, getValues, formState: { errors } } = useForm<RevenueGlClassificationFormI>({
        defaultValues: {
            revenues: []
        },
        resolver: yupResolver(BankRevenueValidate)
    });
    const { fields, append: formAppend, remove: formRemove } = useFieldArray({
        control,
        name: "revenues"
    });

    const { control: control2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm<BankSearchFormI>({
        defaultValues: {
            month: {
                value: '01',
                label: 'January'
            },
            year: { value: '2024', label: '2024' },
        },
        resolver: yupResolver(GLSearchFormValidate)
    });

    // useEffect(() => {
    //     fechAccountList().then(r => {
    //         setGlAccount(r);
    //     })
    // }, [])
    useEffect(() => {
        fetchTransactionList();
        fechBankAccountList().then(r => {
            setGlDebitAccount(r);
        })
        fechAllAccountList().then(r => {
            setGlCreditAccount(r);
        })
    }, [])

    useEffect(() => {
        records.map((record: any, index: number) => {
            const depositData = record['breakdowns'].filter((e: any) => e['account_type'] == "Deposit");
            const equityData = record['breakdowns'].filter((e: any) => e['account_type'] == "Equity");
            const bankTransferData = record['breakdowns'].filter((e: any) => e['account_type'] == "Bank_Transfer");
            const otherData = record['breakdowns'].filter((e: any) => e['account_type'] == "Others");
            const adjustedBankDepositData = record['breakdowns'].filter((e: any) => e['account_type'] == "Adjusted_Bank_Deposit");

            formAppend({
                formIndex: index,
                checked: false,
                id: record['id'],
                parent_id: record['parent_id'],
                description: record['description'],
                account_name: record['account_name'],
                transaction_at: record['transaction_at'],
                deposit: depositData.length && depositData[0]['account_value'] || 0,
                equity: equityData.length && equityData[0]['account_value'] || 0,
                bank_transfer: bankTransferData.length && bankTransferData[0]['account_value'] || 0,
                others: otherData.length && otherData[0]['account_value'] || 0,
                adjusted_bank_deposit: adjustedBankDepositData.length && adjustedBankDepositData[0]['account_value'] || 0,
                equity_master_account_id: null,
                bank_transfer_master_account_id: null,
                others_master_account_id: null,
                deposit_master_account_id: depositData.length && depositData[0]['account_chart'] && {
                    label: depositData[0]['account_chart']['name'] + '(' + depositData[0]['account_chart']['account'] + ')',
                    value: depositData[0]['account_chart']['id']
                } || null,
                // adjusted_bank_deposity_master_account_id: null
            })
        })
        const sumAmount = records.reduce((a: number, record: any) => {
            const equityData = record['breakdowns'].filter((e: any) => e['account_type'] == "Equity");
            const bankTransferData = record['breakdowns'].filter((e: any) => e['account_type'] == "Bank_Transfer");
            const otherData = record['breakdowns'].filter((e: any) => e['account_type'] == "Others");

            a += (equityData.length && equityData[0]['account_value'] || 0) + (bankTransferData.length && bankTransferData[0]['account_value'] || 0) + (otherData.length && otherData[0]['account_value'] || 0);

            return a;
        }, 0)

        if (sumAmount) {
            setNeedFormAttention(true)
        } else {
            setNeedFormAttention(false)
        }

    }, [records])

    useEffect(() => {
        setContentMaxHeight(calculateMaxHeightContent(131));
    })

    useEffect(() => {
        if (fields.length == 0) {
            if (checkAllRef.current) {
                checkAllRef.current.checked = false;
            }
        }
    }, [fields])

    const fetchTransactionList = () => {
        const params: any = {
            "page": 1,
            "per_page": 500,
            year: urlParams['year'],
            month: urlParams['month'],
            branch_id: +branchData['id']
        }

        params['id'] = 0;
        params['account_type'] = 3;

        setIsLoading(true);
        glTransactionlist(params, (message: string, resp: any) => {
            reset();
            setRecords(resp.data.data.result);
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }
    const fechBankAccountList = (searchText: string = ''): Promise<any[]> => {

        return new Promise<any>((resolve, reject) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            params['parent_account_in'] = GL_VP.BANK_ACCOUNT.account.join(',');
            glAccountsList(params, (message: string, resp: any) => {
                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id']
                }));
                return resolve(data);
                //console.log(data);
                // setGlAccount(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve([]);
            })
        })
    }

    const fechAllAccountList = (searchText: string = ''): Promise<any[]> => {
        return new Promise<any>((resolve1, reject1) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            // params['parent_name_in'] = 'BANK ACCOUNTS';
            glAccountsList(params, (message: string, resp: any) => {

                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id']
                }));
                return resolve1(data);
                //console.log(resp);
                // setGlAccount(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve1([]);
            })
        })
    }

    const filterBankAccountList = async (inputValue: string) => {
        const data = await fechBankAccountList(inputValue)
        return data;
    };
    const filterAllAccountList = async (inputValue: string) => {
        const data = await fechAllAccountList(inputValue)
        return data;
    };


    const handleCheckAllChange = (e: any) => {
        if (checkAllRef.current) {
            checkAllRef.current.checked = e.target.checked;

            records.map((record: any, index: number) => {
                setValue(`revenues.${index}.checked`, e.target.checked)
            })
        }
        // setChecked(
        //     e.target.checked ? records.map((c: any) => c.id) : []
        // );
    };

    const handleCheckAll = (e: any, index: number) => {
        setValue(`revenues.${index}.checked`, e.target.checked);
        if (e.target.checked) {
            const checkedRecords = records.filter((record: any, index: number) => getValues(`revenues.${index}.checked`) === true);

            if (checkedRecords.length == records.length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = true;
                }
            }
        } else {
            const uncheckedRecords = records.filter((record: any, index: number) => getValues(`revenues.${index}.checked`) === false);

            if (uncheckedRecords.length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = false;
                }
            }
        }
    }

    const processData = (data: any) => {
        const toRemoveItems: number[] = [];
        const records = data['revenues']
            // .filter((e: any) => e['checked'])
            .map((d: any, index: number) => {
                if (d['checked']) {
                    toRemoveItems.push(index)
                    return {
                        id: d.id,
                        parent_id: d.parent_id,
                        description: d.description,
                        account_name: d.account_name,
                        transaction_at: d.transaction_at,
                        deposit: d.deposit,
                        equity: d.equity,
                        bank_transfer: d.bank_transfer,
                        others: d.others,
                        // adjusted_bank_deposit: d.adjusted_bank_deposit,
                        deposit_master_account_id: d['deposit_master_account_id'] && d['deposit_master_account_id']['value'] && +d['deposit_master_account_id']['value'] || null,
                        equity_master_account_id: d['equity_master_account_id'] && d['equity_master_account_id']['value'] && +d['equity_master_account_id']['value'] || null,
                        bank_transfer_master_account_id: d['bank_transfer_master_account_id'] && d['bank_transfer_master_account_id']['value'] && +d['bank_transfer_master_account_id']['value'] || null,
                        others_master_account_id: d['others_master_account_id'] && d['others_master_account_id']['value'] && +d['others_master_account_id']['value'] || null
                    }
                } else {
                    return null;
                }
            }).filter((e: any) => e);

        postData(records, toRemoveItems)
    }
    const postData = (records: any, toRemoveItems: number[]) => {
        if (records.length) {
            setIsLoading(true);
            revenueGlUpdate({ records, branch_id: +branchData['id'] }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.success(message)
                formRemove(toRemoveItems)
                if (records.length == fields.length) {
                    navigate(-1);
                }
                // fetchTransactionList();
            }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.error(message);
            })
        } else {
            toast.error("Please select at least one record");
        }
    }
    const processSearchData = (data: any) => {
        setSelectedCalendar({
            year: +data['year']['value'],
            month: +data['month']['value']
        })
    }
    const handleSinglePost = (index: number) => {
        const data = getValues();
        const toRemoveItems: number[] = [];
        if (data['revenues'][index]['checked']) {
            const records = [data['revenues'][index]]
                .map((d: any) => {
                    return {
                        id: d.id,
                        parent_id: d.parent_id,
                        description: d.description,
                        account_name: d.account_name,
                        transaction_at: d.transaction_at,
                        deposit: d.deposit,
                        equity: d.equity,
                        bank_transfer: d.bank_transfer,
                        others: d.others,
                        // adjusted_bank_deposit: d.adjusted_bank_deposit,
                        deposit_master_account_id: d['deposit_master_account_id'] && d['deposit_master_account_id']['value'] && +d['deposit_master_account_id']['value'] || null,
                        equity_master_account_id: d['equity_master_account_id'] && d['equity_master_account_id']['value'] && +d['equity_master_account_id']['value'] || null,
                        bank_transfer_master_account_id: d['bank_transfer_master_account_id'] && d['bank_transfer_master_account_id']['value'] && +d['bank_transfer_master_account_id']['value'] || null,
                        others_master_account_id: d['others_master_account_id'] && d['others_master_account_id']['value'] && +d['others_master_account_id']['value'] || null
                    }
                });
            toRemoveItems.push(index)
            postData(records, toRemoveItems)
        } else {
            toast.error("Please select the record");
        }
    }
    const handleFieldValidation = async (index: number) => {
        // Trigger validation for the specified field within the array
        const isValidated = await trigger(`revenues.${index}`);
        if (isValidated) {
            handleSinglePost(index)
        }
    };

    const onGlAccSelected = (e: any, index: number, field: string, onChange: Function) => {
        console.log('index: ', index, ' field: ', field)
        // onChange(e);
        // setFocus(`revenues.${index + 1}.${field as keyof RevenueGlClassificationSingleFormI}`)
        setFocus(fields[1].deposit_master_account_id)
        // setFocus(`revenues[${index + 1}].deposit_master_account_id`);
        // setValue(`revenues.${index + 1}.deposit_master_account_id`, "gfhf");
        // setTimeout(() => setFocus(`revenues.${index}.equity_master_account_id`), 1);
    }

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Revenue GL Classification</h1>
                        </div>
                        <div className="col-sm-6 justify-content-end align-items-center d-flex">
                            <Link to={URLS.REVENUE_GL_CLASSIFICATION_STATUS} className="btn btn-link">Back To Classification Status</Link>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className="filter-btn">
                            <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                <div className="loader"></div>
                            </div>
                        </div>
                        <Form>
                            {
                                !needFormAttention && (
                                    <Alert variant={'info'}>
                                        <i className="fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i>&nbsp;&nbsp;
                                        No adjustment required. Please post.
                                    </Alert>
                                ) || <></>
                            }
                            {
                                fields.length > 0 && (
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={handleSubmit(processData)}>Post</button>
                                    </div> || <></>
                                )
                            }

                            <br></br>
                            <div className="table-responsive list-table table-scroll" style={{ maxHeight: contentMaxHeight }}>
                                <table className="table table-default">
                                    <thead>
                                        <tr className="align-middle">
                                            <th className="w-5 text-center">
                                                <div className="form-check ">
                                                    <label >
                                                        <input ref={checkAllRef} className="form-check-input wh-20 checkAll" type="checkbox" onChange={handleCheckAllChange} />
                                                    </label>
                                                </div>
                                            </th>
                                            <th className="text-center">Date</th>
                                            <th className="text-start">Description </th>
                                            <th className="text-end">Deposit </th>
                                            <th className="text-start">Debit GL Account </th>
                                            <th className="text-end">Equity </th>
                                            <th className="text-start">Credit GL Account </th>
                                            <th className="text-end">Bank Transfer </th>
                                            <th className="text-start">Credit GL Account </th>
                                            <th className="text-end">Others </th>
                                            <th className="text-start">Credit GL Account </th>
                                            <th className="text-end">Adjusted Bank Deposit </th>
                                            {/* <th className="text-center">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fields.length > 0 ?
                                                fields.map((data: any, index: any) => (
                                                    <tr className="align-middle h-60" key={data['id']}>
                                                        <td className="text-center">
                                                            <div className="form-check ">
                                                                <label >
                                                                    <input className="form-check-input wh-20 checkbox" {...register(`revenues.${index}.checked`)}
                                                                        name={`revenues.${index}.checked`}
                                                                        defaultChecked={fields[index]['checked']}
                                                                        type="checkbox" onChange={(e: any) => handleCheckAll(e, index)} />

                                                                    {/* <input className="form-check-input wh-20 checkbox" {...register(`revenues.${index}.checkId`)} name={`revenues.${index}.checkId`}
                                                                        checked={checked.includes(data.id)} onChange={(e) => handleCheckboxChange(e, data)} value={data.id} type="checkbox" /> */}
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className="text-center"><strong>{moment(fields[index].transaction_at).format('MM-DD-YYYY h:mm:ss')}</strong></td>
                                                        <td className="text-start text-truncate" style={{ maxWidth: '250px' }} title={fields[index].description || 'NA'}>{fields[index].description || 'NA'}</td>
                                                        <td className="text-end">{currencyFormat(fields[index].deposit)}</td>
                                                        <td className="text-start">
                                                            <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                <Controller
                                                                    control={control}
                                                                    name={`revenues.${index}.deposit_master_account_id`}
                                                                    render={({ field: { value, onChange, onBlur } }) => (
                                                                        <AsyncSelect
                                                                            isClearable={true}
                                                                            placeholder={'Please select GL acc'}
                                                                            defaultOptions={glDebitAccount}
                                                                            loadOptions={filterBankAccountList}
                                                                            onChange={(e: any) => {
                                                                                onChange(e);
                                                                                onGlAccSelected(e, index, 'deposit_master_account_id', onChange)
                                                                            }}
                                                                            className='vp-dropdown'
                                                                            menuPosition='fixed'
                                                                            value={value}
                                                                            defaultValue={value}
                                                                            styles={selectCustomStyle}
                                                                            components={{ Option: SelectCustomOption }}
                                                                            isDisabled={!!fields[index].deposit_master_account_id}
                                                                        />
                                                                    )}
                                                                />
                                                                {
                                                                    !!fields[index].deposit_master_account_id && <input {...register(`revenues.${index}.deposit_master_account_id`)} type="hidden" />
                                                                }
                                                            </Form.Group>
                                                            {
                                                                errors && errors.revenues && errors.revenues[index] && errors.revenues[index]?.deposit_master_account_id && errors.revenues[index]?.deposit_master_account_id?.message && (
                                                                    <Form.Text className="text-danger d-flex">
                                                                        <strong>
                                                                            {errors.revenues[index]?.deposit_master_account_id?.message as ReactNode || ''}
                                                                        </strong>
                                                                    </Form.Text>
                                                                ) || ''
                                                            }
                                                        </td>
                                                        <td className="text-end">{currencyFormat(fields[index].equity)}</td>
                                                        <td className="text-start">
                                                            <Form.Group className="col-sm-12" controlId="formBasicEmail">
                                                                <Controller
                                                                    disabled={!fields[index].equity}
                                                                    control={control}
                                                                    name={`revenues.${index}.equity_master_account_id`}
                                                                    render={({ field: { value, onChange, disabled } }) => (
                                                                        <AsyncSelect
                                                                            isClearable={true}
                                                                            placeholder={'Please select GL acc'}
                                                                            defaultOptions={glCreditAccount}
                                                                            loadOptions={filterAllAccountList}
                                                                            onChange={onChange}
                                                                            className='vp-dropdown'
                                                                            menuPosition='fixed'
                                                                            isDisabled={disabled}
                                                                            styles={selectCustomStyle}
                                                                            components={{ Option: SelectCustomOption }}
                                                                        />
                                                                    )}
                                                                />
                                                            </Form.Group>
                                                            {
                                                                errors && errors.revenues && errors.revenues[index] && errors.revenues[index]?.equity_master_account_id && errors.revenues[index]?.equity_master_account_id?.message && (
                                                                    <Form.Text className="text-danger d-flex">
                                                                        <strong>{errors.revenues[index]?.equity_master_account_id?.message as ReactNode || ''}</strong>
                                                                    </Form.Text>
                                                                ) || ''
                                                            }
                                                        </td>
                                                        <td className="text-end">{currencyFormat(fields[index].bank_transfer)}</td>
                                                        <td className="text-start">
                                                            <Form.Group className="col-sm-12" controlId="formBasicEmail">
                                                                <Controller
                                                                    control={control}
                                                                    disabled={!fields[index].bank_transfer}
                                                                    name={`revenues.${index}.bank_transfer_master_account_id`}
                                                                    render={({ field: { value, onChange, disabled } }) => (
                                                                        <AsyncSelect
                                                                            isClearable={true}
                                                                            placeholder={'Please select GL acc'}
                                                                            defaultOptions={glCreditAccount} loadOptions={filterAllAccountList}
                                                                            onChange={onChange}
                                                                            className='vp-dropdown'
                                                                            menuPosition='fixed'
                                                                            isDisabled={disabled}
                                                                            styles={selectCustomStyle}
                                                                            components={{ Option: SelectCustomOption }}
                                                                        />
                                                                    )}
                                                                />
                                                            </Form.Group>
                                                            {
                                                                errors && errors.revenues && errors.revenues[index] && errors.revenues[index]?.bank_transfer_master_account_id && errors.revenues[index]?.bank_transfer_master_account_id?.message && (
                                                                    <Form.Text className="text-danger d-flex">
                                                                        <strong>{errors.revenues[index]?.bank_transfer_master_account_id?.message as ReactNode || ''}</strong>
                                                                    </Form.Text>
                                                                ) || ''
                                                            }
                                                        </td>
                                                        <td className="text-end">{currencyFormat(fields[index].others)}</td>
                                                        <td className="text-start">
                                                            <Form.Group className="col-sm-12" controlId="formBasicEmail">
                                                                <Controller
                                                                    control={control}
                                                                    disabled={!fields[index].others}
                                                                    name={`revenues.${index}.others_master_account_id`}
                                                                    render={({ field: { value, onChange, disabled } }) => (
                                                                        <AsyncSelect
                                                                            isClearable={true}
                                                                            placeholder={'Please select GL acc'}
                                                                            defaultOptions={glCreditAccount}
                                                                            loadOptions={filterAllAccountList}
                                                                            onChange={onChange}
                                                                            className='vp-dropdown'
                                                                            menuPosition='fixed'
                                                                            isDisabled={disabled}
                                                                            styles={selectCustomStyle}
                                                                            components={{ Option: SelectCustomOption }}
                                                                        />
                                                                    )}
                                                                />
                                                            </Form.Group>
                                                            {
                                                                errors && errors.revenues && errors.revenues[index] && errors.revenues[index]?.others_master_account_id && errors.revenues[index]?.others_master_account_id?.message && (
                                                                    <Form.Text className="text-danger d-flex">
                                                                        <strong>{errors.revenues[index]?.others_master_account_id?.message as ReactNode || ''}</strong>
                                                                    </Form.Text>
                                                                ) || ''
                                                            }
                                                        </td>
                                                        <td className="text-end">{currencyFormat(fields[index].adjusted_bank_deposit)}</td>
                                                        {/* <td className="text-center">
                                                            <button type="button" className="btn btn-secondary" onClick={() => handleFieldValidation(index)}>Post</button>
                                                        </td> */}
                                                    </tr>
                                                )) : <tr>
                                                    <td colSpan={12} className='text-center'>No data found</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                        {/* <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} recordPerPage={recordPerPage} lastIndex={lastIndex} firstIndex={firstIndex} npage={npage} /> */}
                    </div>

                    {/* {
                        <RevenueClassificationConfirmModal 
                            closeModal={} 
                            records={}
                        />
                    } */}

                </div>
            </div>
        </div>

    )
}

export default RevenueGlClassification;