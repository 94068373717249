
// import React from 'react'
import { Routes, Route } from 'react-router-dom';
// import { useRoutes } from "react-router-dom";
import { URLS } from '../resources/constants'

import Header from '../layouts/Header'
import Login from '../scenes/Login'
import Dashboard from '../scenes/Dashboard'
import PrivateRoutes from '../hoc/PrivateRoutes';
import UserMappedRoutes from '../hoc/UserMappedAccount';
import BankTransaction from '../scenes/Transactions/BankTransactions';
import UserProfile from '../scenes/UserProfile';
import Budget from '../scenes/Budget';
import Settings from '../scenes/Settings';
import BalanceSheet from '../scenes/BalanceSheet';
import ChartOfAccounts from '../scenes/ChartOfAccounts';
import UserVerification from '../scenes/UserVerification';
import ExpenseGlClassification from '../scenes/GlClassification/ExpenseGlClassification/ExpenseGlClassification';
import ExpenseGlClassificationStatus from '../scenes/GlClassification/ExpenseGlClassification/ExpenseGlClassificationStatus';
import SalesGlClassification from '../scenes/GlClassification/SalesGlClassification/SalesGlClassification';
import SalesGlClassificationStatus from '../scenes/GlClassification/SalesGlClassification/SalesGlClassificationStatus';
// import CreditGlClassification from '../scenes/GlClassification/CreditGlClassification';
import RevenueGlClassification from '../scenes/GlClassification/RevenueGlClassification/RevenueGlClassification';
import RevenueGlClassificationStatus from '../scenes/GlClassification/RevenueGlClassification/RevenueGlClassificationStatus';
import BankFeed from '../scenes/GlClassification/BankFeed';
import SaleTransaction from '../scenes/Transactions/SalesTransactions';
import ViewRegister from '../scenes/GlClassification/ViewRegister';
import ProfitLoss from '../scenes/ProfitLoss';
import FourThreeThreeB from '../scenes/FourThreeThreeB';
import BankReconcile from '../scenes/BankReconcile';
import BankReconcileTxn from '../scenes/BankReconcile/BankReconcileTxn';
import StatementOfCashFlow from '../scenes/StatementOfCashFlow';
import UserStpesRoutes from '../hoc/UserStpesCheck';
import NotFound from '../scenes/NotFound';
import BusinessRatio from 'scenes/BusinesssRatio';

const Router: React.FC = () => {
    return (
        <div>
            <Routes>
                <Route element={<PrivateRoutes />}>
                    <Route element={<Header />} path="/"> 
                        <Route element={<Dashboard/>} path={URLS.HOME} />
                        <Route element={<Dashboard/>} path={URLS.DASHBOARD} />
                        <Route element={<ChartOfAccounts/>} path={URLS.CHARTACCOUNT} />
                        <Route element={<UserStpesRoutes />}>
                            <Route element={<BankTransaction/>} path={URLS.BANK_TRANSACTION} />
                            <Route element={<UserProfile/>} path={URLS.USERPROFILE} />
                            <Route element={<Settings/>} path={URLS.SETTINGS} />
                            <Route element={<BalanceSheet/>} path={URLS.BALANCESHEET} />
                            <Route element={<Budget />} path={URLS.BUDGET} />
                            <Route element={<BankFeed/>} path={URLS.BANK_FEED} />
                            <Route element={<SaleTransaction/>} path={URLS.SALES_TRANSACTION} />
                            <Route element={<ViewRegister/>} path={URLS.VIEW_REGISTER} />
                            <Route element={<ProfitLoss/>} path={URLS.PROFITLOSS} />
                            <Route element={<FourThreeThreeB/>} path={URLS.FOURTHREETHREEB} />
                            <Route element={<StatementOfCashFlow/>} path={URLS.STATEMENT_OF_CASH_FLOW} />
                            <Route element={<BankReconcile />} path={URLS.BANK_RECONCILE} />
                            <Route element={<BankReconcileTxn />} path={URLS.BANK_RECONCILE_TRANSACTIONS} />
                            <Route element={<BusinessRatio />} path={URLS.BUSINESS_RATIO} />
                        </Route>
                        <Route element={<UserMappedRoutes />}>
                            <Route element={<ExpenseGlClassification/>} path={URLS.EXPENSE_GL_CLASSIFICATION} />
                            <Route element={<ExpenseGlClassificationStatus/>} path={URLS.EXPENSE_GL_CLASSIFICATION_STATUS} />

                            <Route element={<RevenueGlClassification />} path={URLS.REVENUE_GL_CLASSIFICATION} />
                            <Route element={<RevenueGlClassificationStatus />} path={URLS.REVENUE_GL_CLASSIFICATION_STATUS} />

                            <Route element={<SalesGlClassification/>} path={URLS.SALES_GL_CLASSIFICATION} />
                            <Route element={<SalesGlClassificationStatus/>} path={URLS.SALES_GL_CLASSIFICATION_STATUS} />
                        </Route>
                        
                    </Route>
                </Route>
                <Route element={<Login/>} path={URLS.LOGIN} />
                <Route element={<UserVerification/>} path={URLS.USER_VERIFICATION} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </div>
    )
}

export default Router;