import React, { useEffect, useState, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form'
import { useForm, Controller } from 'react-hook-form'
import filterSolidIcon from '../../assets/images/filter-solid-icon.svg'
import Pagination from '../../components/Pagination'
import { transTableDataApi } from '../../resources/hooks/api/transApiHook'
import { currencyFormat } from '../../resources/helper'
import 'react-datepicker/dist/react-datepicker.css'
import { BankTransFormI } from '../../resources/form-props'
import SAlert from '../../components/SAlert'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { BankTransSearchFormI } from '../../resources/form-props'
import { GL_VP, MONTHS, SELECT_CUSTOM_STYLE } from '../../resources/constants'
import toast from 'react-hot-toast'
import moment from 'moment'
// import './filter.css';
import { calculateMaxHeightContent, fileDownloadBlob, lastYears } from '../../resources/functions'
import { getBranch } from '../../store/user/selectors'
import { useChartAccountApi } from '../../resources/hooks/api/chartAccountApiHook'
import SelectCustomOption from '../../components/SelectCustomOption'
import DatePicker from 'react-datepicker'
import { useReportApi } from '../../resources/hooks/api/useReportApiHook'

interface Props {}

const BankTransaction: React.FC<Props> = () => {
  const {  downloadBTReport } = useReportApi();
  const { fetchBankTransData } = transTableDataApi()
  
  const [record, setRecords] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [revenueData, setRevenueData] = useState<any>([])
  const [expenseData, setExpenseData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [filterData, setFilterData] = useState<any>({
    year: 2024,
    month: 1,
    trans_type: 1,
    account: null,
    date_range: null,
  })
  const [transationType, setTransationType] = useState<any>(1)
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null })
  const [minDate, setMinDate] = useState(new Date(filterData.year, filterData.month - 1, 1));
  const [maxDate, setMaxDate] = useState(new Date(filterData.year, filterData.month, 0));

  const {
    control: control2,
    watch,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue,
  } = useForm<BankTransSearchFormI>({
    defaultValues: {
      account: null,
      dateRange: { startDate: null, endDate: null },
      month: {
        value: '01',
        label: 'January',
      },
      year: { value: '2024', label: '2024' },
      trans_type: { value: '1', label: 'Withdrawal' },
    },
    //resolver: yupResolver(GLSearchFormValidate)
  })

 
  // Start Paggination
  const [currentPage, setCurrentPage] = useState<any>(1)

  // const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage] = useState<any>(5000) // Change this value as needed
  const [totalPages, setTotalPages] = useState<any>(1)

  const [firstIndex, setFirstIndex] = useState<any>(1)
  const [lastIndex, setLastIndex] = useState<any>(1)

  const { glAccountsList } = useChartAccountApi()

  const [totalDeposit, setTotalDeposit] = useState<any>(0)
  const [totalEquity, setTotalEquity] = useState<any>(0)
  const [totalBankTransfer, setTotalBankTransfer] = useState<any>(0)
  const [totalOthers, setTotalOthers] = useState<any>(0)
  const [totalAdjustedBankDeposit, setTotalAdjustedBankDeposit] = useState<any>(
    0
  )
  const [totalExpense, setTotalExpense] = useState<any>(0)
  const [contentMaxHeight, setContentMaxHeight] = useState<number>(0)
  const [selectCustomStyle, setSlectCustomStyle] = useState<any>(
    SELECT_CUSTOM_STYLE('#fff')
  )

  const branchData = useSelector(getBranch)

  const selectedYear = watch('year', ''); 
  const selectedMonth = watch('month', ''); 

  useEffect(() => {
    fetchTransactionList()
  }, [filterData])

  useEffect(() => {
    setContentMaxHeight(calculateMaxHeightContent(78))
  })


  useEffect(() => {
    setMinDate(new Date(selectedYear.value, selectedMonth.value - 1, 1));
    setMaxDate(new Date(selectedYear.value, selectedMonth.value, 0));
  }, [selectedYear,selectedMonth])

  useEffect(() => {
    setDateRange({
      startDate: null,
      endDate: null,
    });
  }, [minDate, maxDate])

  const fetchTransactionList = () => {
    const params: any = {
      page: currentPage,
      per_page: recordPerPage,
      year: +filterData['year'],
      month: +filterData['month'],
      account_type: +filterData['trans_type'],
      branch_id: +branchData['id'],
    }

    if (filterData['account']) {
      params.account = +filterData['account']
    }

    if (filterData['dateRange'] && filterData['dateRange'].startDate) {
      params.start_day = moment(filterData['dateRange'].startDate).format('D')
    }

    if (filterData['dateRange'] && filterData['dateRange'].endDate) {
      params.end_day = moment(filterData['dateRange'].endDate).format('D')
    }

    let tempTotalDeposit = 0
    let tempTotalEquity = 0
    let tempTotalBankTransfer = 0
    let tempTotalOthers = 0
    let tempTotalAdjustedBankDeposit = 0
    let tempTotalExpense = 0

    setIsLoading(true)

    fetchBankTransData(
      params,
      (message: string, resp: any) => {
        const result = resp.data.data.result

        // Set pagination values
        setFirstIndex(resp.data.data.page)
        setLastIndex(resp.data.data.total)
        setTotalPages(resp.data.data.total)
        const push_rec: any[] = []

        result.map((rec: any, index: any) => {
          if (rec.account_type == 3) {
            const depositData = rec['breakdowns'].filter(
              (e: any) => e['account_type'] == 'Deposit'
            )
            const equityData = rec['breakdowns'].filter(
              (e: any) => e['account_type'] == 'Equity'
            )
            const bankTransferData = rec['breakdowns'].filter(
              (e: any) => e['account_type'] == 'Bank_Transfer'
            )
            const othersData = rec['breakdowns'].filter(
              (e: any) => e['account_type'] == 'Others'
            )
            const abdData = rec['breakdowns'].filter(
              (e: any) => e['account_type'] == 'Adjusted_Bank_Deposit'
            )

            const rec1 = {
              parent_id: rec.parent_id,
              account_type: rec.account_type,
              description: rec.description,
              account_name: rec.account_name,
              transaction_at: rec.transaction_at,
              deposit:
                (depositData.length && depositData[0]['account_value']) || 0,
              equity:
                (equityData.length && equityData[0]['account_value']) || 0,
              bank_transfer:
                (bankTransferData.length &&
                  bankTransferData[0]['account_value']) ||
                0,
              others:
                (othersData.length && othersData[0]['account_value']) || 0,
              adjusted_bank_deposit:
                (abdData.length && abdData[0]['account_value']) || 0,
              posted: rec.updated_at ? 'YES' : 'NO',
              account:
                (depositData.length &&
                  depositData[0]['account_chart'] &&
                  depositData[0]['account_chart'].account) ||
                'NA',
            }
            tempTotalDeposit += Math.abs(rec1.deposit)
            tempTotalEquity += Math.abs(rec1.equity)
            tempTotalBankTransfer += Math.abs(rec1.bank_transfer)
            tempTotalOthers += Math.abs(rec1.others)
            tempTotalAdjustedBankDeposit += Math.abs(rec1.adjusted_bank_deposit)

            push_rec.push(rec1)

            // totalExpense += transationType === 1 ? Math.abs(rec.expense) : 0;
          } else {
            const expenseData = rec['breakdowns'].filter(
              (e: any) => e['account_type'] == 'Credit_Deposit'
            )
            const rec2 = {
              parent_id: rec.parent_id,
              account_type: rec.account_type,
              description: rec.description,
              account_name: rec.account_name,
              transaction_at: rec.transaction_at,
              expense:
                (expenseData.length && expenseData[0]['account_value']) || 0,
              account:
                (expenseData.length &&
                  expenseData[0]['account_chart'] &&
                  expenseData[0]['account_chart'].account) ||
                'NA',
              posted: rec.updated_at ? 'YES' : 'NO',
            }
            tempTotalExpense += Math.abs(rec2.expense)

            push_rec.push(rec2)
          }

          //storeData.push(main_rec);
        })

        setData(push_rec)

        // Update totals

        setTotalDeposit(tempTotalDeposit)
        setTotalEquity(tempTotalEquity)
        setTotalBankTransfer(tempTotalBankTransfer)
        setTotalOthers(tempTotalOthers)
        setTotalAdjustedBankDeposit(tempTotalAdjustedBankDeposit)
        setTotalExpense(tempTotalExpense)

        setIsLoading(false)
      },
      (message: string, resp: any) => {
        setIsLoading(false)
        toast.error(message)
      }
    )
  }
  const processSearchData = (data: any) => {
    setFilterData({
      year: +data['year']['value'],
      month: +data['month']['value'],
      trans_type: +data['trans_type']['value'],
      account: data['account'] ? +data['account']['account'] : null,
      dateRange: data['dateRange'],
    })
    setTransationType(+data['trans_type']['value'])
  }

  const fetchAccountList = (searchText: string = ''): Promise<any[]> => {
    return new Promise<any>((resolve, reject) => {
      const params: any = {
        page: 1,
        per_page: 10,
        branch_id: +branchData['id'],
      }
      if (searchText) {
        params['name'] = searchText
      }
      params['parent_account_in'] = GL_VP.BANK_ACCOUNT.account.join(',')
      params['include_assigned_vp'] = 1

      glAccountsList(
        params,
        (message: string, resp: any) => {
          const data = resp.data.data.result.map((e: any) => ({
            label: e['name'] + `(${e['account']})`,
            value: e['id'],
            account: e['account'],
          }))
          return resolve(data)
        },
        (message: string, resp: any) => {
          toast.error(message)
          return resolve([])
        }
      )
    })
  }

  const filterAccountList = async (inputValue: string) => {
    const data = await fetchAccountList(inputValue)
    return data
  }

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    
    setValue('dateRange', { startDate: start, endDate: end })
    setDateRange({ ...dateRange, startDate: start, endDate: end })
  }

  const _downloadReport = () => {
    const params: any = {
      page: currentPage,
      per_page: recordPerPage,
      year: +filterData['year'],
      month: +filterData['month'],
      account_type: +filterData['trans_type'],
      branch_id: +branchData['id'],
    }

    if (filterData['account']) {
      params.account = +filterData['account']
    }

    if (filterData['dateRange'] && filterData['dateRange'].startDate) {
      params.start_day = moment(filterData['dateRange'].startDate).format('D')
    }

    if (filterData['dateRange'] && filterData['dateRange'].endDate) {
      params.end_day = moment(filterData['dateRange'].endDate).format('D')
    }

    setIsLoading(true);
    downloadBTReport(params, (message: string, resp: any) => {
          setIsLoading(false);
          fileDownloadBlob(resp, `bank-transaction-${filterData.year}-${filterData.month}`)
    }, (message: string, resp: any) => {
          setIsLoading(false);
          toast.error(message);
    })
  }
 
 


  return (
    <div className="main-container flex-grow-1">
      <div className="container-fluid">
        <div className="page-title pb-4 pt-3" id="ux_page_title">
          <div className="row align-items-center">
            <div className="col-sm-6 align-items-center d-flex">
              <h1 className="h3 font-weight-700 mb-0 d-inline-flex">
                Bank Transactions
              </h1>
            </div>
          </div>
        </div>

        <div className=" executive-dashboard">
          <div className="dashboard-top-part">
            <div className="table-top" id="ux_page_filter">
              <div className="row">
                <Form>
                  <div className="col-sm-12">
                    <div className="filter-wrap">
                      <Form.Group
                        className="filter-box"
                        controlId="formBasicEmail"
                      >
                        <Controller
                          name="month"
                          control={control2}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={MONTHS}
                              className={'zindex-10'}
                            />
                          )}
                        />
                        {(errors2 &&
                          errors2['month'] &&
                          errors2['month'].message && (
                            <Form.Text className="text-danger">
                              {(errors2['month'].message as ReactNode) || ''}
                            </Form.Text>
                          )) ||
                          ''}
                      </Form.Group>
                      <Form.Group
                        className="filter-box"
                        controlId="formBasicEmail"
                      >
                        <Controller
                          name="year"
                          control={control2}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={lastYears(5)}
                              className={'zindex-10'}
                            />
                          )}
                        />
                        {(errors2 &&
                          errors2['year'] &&
                          errors2['year'].message && (
                            <Form.Text className="text-danger">
                              {(errors2['year'].message as ReactNode) || ''}
                            </Form.Text>
                          )) ||
                          ''}
                      </Form.Group>

                      <Form.Group
                        className="filter-box"
                        controlId="formBasicEmail"
                      >
                        <Controller
                          name="trans_type"
                          control={control2}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={[
                                { value: '1', label: 'Withdrawal' },
                                { value: '3', label: 'Deposit' },
                              ]}
                              className={'zindex-10'}
                            />
                          )}
                        />
                        {(errors2 &&
                          errors2['trans_type'] &&
                          errors2['trans_type'].message && (
                            <Form.Text className="text-danger">
                              {(errors2['trans_type'].message as ReactNode) ||
                                ''}
                            </Form.Text>
                          )) ||
                          ''}
                      </Form.Group>

                      <Form.Group
                        className="filter-box"
                        controlId="formBasicEmail"
                      >
                        <Controller
                          name="account"
                          control={control2}
                          render={({ field }) => (
                            <AsyncSelect
                              {...field}
                              isClearable={true}
                              placeholder={'Select a bank'}
                              defaultOptions
                              loadOptions={filterAccountList}
                              className={'zindex-10'}
                              menuPosition="fixed"
                              styles={selectCustomStyle}
                              components={{ Option: SelectCustomOption }}
                              // isDisabled={isSelecteBank}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group
                        className="filter-box"
                        controlId="formBasicEmail"
                      >
                        <Controller
                          name="dateRange"
                          control={control2}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              selected={dateRange.startDate}
                              onChange={(date) => handleDateChange(date)}
                              selectsRange
                              startDate={dateRange.startDate}
                              endDate={dateRange.endDate}
                              minDate={minDate}
                              maxDate={maxDate}
                              className={'form-control datepicker-transaction'}
                              placeholderText="Select Date Range"
                            />
                          )}
                        />
                      </Form.Group>

                      <div className="filter-btn">
                        <button
                          type="button"
                          className="button"
                          onClick={handleSubmit2(processSearchData)}
                        >
                          <img src={filterSolidIcon} alt="" /> Filter
                        </button>
                      </div>

                      <div className="filter-btn">
                        <div
                          className={`page-loader ${
                            isLoading ? 'visible' : ''
                          }`}
                        >
                          <div className="loader"></div>
                        </div>
                      </div>

                      {
                                  data.length ? (
                                        <div className="filter-box text-end">
                                            <button type="button" onClick={_downloadReport}  className="btn-dark btn btn-lg">
                                                Export PDF &nbsp;
                                                <i className="fa fa-download" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    ) : <></>
                      }

                     
                    </div>
                  </div>

                  
                </Form>
                
              </div>
            </div>

            <div
              className="table-responsive list-table table-scroll"
              style={{ maxHeight: contentMaxHeight }}
            >
              <table className="table table-default">
                <thead className="zindex-0">
                  <tr className="align-middle">
                    <th className="text-start">#</th>
                    <th className="text-center">Date</th>
                    <th className="text-center">COA Number</th>
                    <th className="text-start">Description </th>
                    {transationType == 3 ? (
                      <>
                        <th className="text-end">Deposite</th>
                        <th className="text-end">Equity </th>
                        <th className="text-end">Bank Transfer</th>
                        <th className="text-end">Other</th>
                        <th className="text-end">ABD</th>
                      </>
                    ) : transationType == 1 ? (
                      <>
                        <th className="text-end">Expense</th>
                      </>
                    ) : (
                      <></>
                    )}

                    <th className="text-center">Posted</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    <>
                      {data.map((rec: any, index: any) => {
                        return (
                          <React.Fragment key={index}>
                            <tr className="align-middle h-60">
                              <td className="text-start">{index + 1}</td>
                              <td className="text-center">
                                <strong>
                                  {moment(rec.transaction_at).format(
                                    'MM-DD-YYYY'
                                  )}
                                </strong>
                              </td>
                              <td className="text-center">{rec.account}</td>
                              <td
                                className="text-start text-truncate"
                                style={{ maxWidth: '110px' }}
                                title={rec.description || 'NA'}
                              >
                                {rec.description || 'NA'}
                              </td>
                              {transationType === 3 ? (
                                <>
                                  <td className="text-end">
                                    {currencyFormat(Math.abs(rec.deposit))}
                                  </td>
                                  <td className="text-end">
                                    {currencyFormat(Math.abs(rec.equity))}
                                  </td>
                                  <td className="text-end">
                                    {currencyFormat(
                                      Math.abs(rec.bank_transfer)
                                    )}
                                  </td>
                                  <td className="text-end">
                                    {currencyFormat(Math.abs(rec.others))}
                                  </td>
                                  <td className="text-end">
                                    {currencyFormat(
                                      Math.abs(rec.adjusted_bank_deposit)
                                    )}
                                  </td>
                                </>
                              ) : transationType === 1 ? (
                                <>
                                  <td className="text-end">
                                    {currencyFormat(Math.abs(rec.expense))}
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                              <td className="text-center">{rec.posted}</td>
                            </tr>
                          </React.Fragment>
                        )
                      })}
                      <tr className="align-middle h-60">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-right">
                          <strong>Total</strong>
                        </td>
                        {transationType === 3 ? (
                          <>
                            <td className="text-end">
                              {currencyFormat(
                                Math.abs(totalDeposit).toFixed(2)
                              )}
                            </td>
                            <td className="text-end">
                              {currencyFormat(Math.abs(totalEquity).toFixed(2))}
                            </td>
                            <td className="text-end">
                              {currencyFormat(
                                Math.abs(totalBankTransfer).toFixed(2)
                              )}
                            </td>
                            <td className="text-end">
                              {currencyFormat(Math.abs(totalOthers).toFixed(2))}
                            </td>
                            <td className="text-end">
                              {currencyFormat(
                                Math.abs(totalAdjustedBankDeposit).toFixed(2)
                              )}
                            </td>
                          </>
                        ) : transationType === 1 ? (
                          <>
                            <td className="text-end">
                              {currencyFormat(
                                Math.abs(totalExpense).toFixed(2)
                              )}
                            </td>
                          </>
                        ) : (
                          <></>
                        )}
                        <td></td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td
                        colSpan={transationType === 3 ? 9 : 6}
                        className="text-center"
                      >
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} recordPerPage={recordPerPage} lastIndex={lastIndex} firstIndex={firstIndex} npage={totalPages} fetchData={fetchTransactionList} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankTransaction
