import { Outlet, Navigate, matchPath, matchRoutes, useLocation, useParams } from 'react-router-dom'
import { URLS } from '../../resources/constants'
import { userSteps } from '../../resources/hooks/userStepsHook'

const UserStpesCheck = () => {
    const userSetpsChesk = userSteps();
    const location = useLocation();
    const params = useParams();

    return(
        URLS.BUDGET == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> : 
        URLS.BANK_TRANSACTION == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        URLS.USERPROFILE == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        URLS.SETTINGS == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        URLS.BALANCESHEET == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        URLS.CHARTACCOUNT == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        URLS.BANK_FEED == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        URLS.SALES_TRANSACTION == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        URLS.VIEW_REGISTER == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        URLS.PROFITLOSS == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        URLS.FOURTHREETHREEB == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        URLS.STATEMENT_OF_CASH_FLOW == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        URLS.BANK_RECONCILE == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        params['coa'] && URLS.BANK_RECONCILE_TRANSACTIONS.replace(":coa", params['coa']) == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        URLS.BUSINESS_RATIO == location['pathname'] && userSetpsChesk >= 3 ? <Outlet/> :
        <Navigate to={URLS.DASHBOARD} />
    )
}

export default UserStpesCheck