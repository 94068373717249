import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SettingsI } from './types'
import { MONTHS, BUDGET_BASED } from '../../resources/constants'
import { transformMonths } from '../../resources/functions';

export const initialState: SettingsI = {
  date_time: "",
  logo: '',
  accouting: {
    first_month_of_fiscal_year: null,
    first_month_of_income_tax_year: null,
    budget_based_on: null
  }
}

const selectedValue = (source: any[], value: number) => {
  const data = source.filter((record: any) => +record['value'] === value)
  return data.length && data[0] || null;
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateSettings(state, action: PayloadAction<any>) {
      console.log('settings: ', action)
      state.date_time = '22323232' //action.payload.date_time
      state.logo = 'fghg' //action.payload.logo
    },
    updateAccounting(state, action: PayloadAction<any>) {
      state.accouting.first_month_of_fiscal_year = selectedValue(transformMonths(), action.payload.first_month_of_fiscal_year)
      state.accouting.first_month_of_income_tax_year = selectedValue(transformMonths(), action.payload.first_month_of_income_tax_year)
      state.accouting.budget_based_on = selectedValue(BUDGET_BASED, action.payload.budget_based_on)
    },
  },
})

export const { reducer } = settingsSlice

export const { updateSettings, updateAccounting } = settingsSlice.actions
