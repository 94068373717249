import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import toast from 'react-hot-toast';
import moment from 'moment';
import { currencyFormat } from '../../../resources/helper';

interface SplitModalProps {
    rowID: number;
    show: boolean;
    onHide: () => void;
    onSave: (rowID: any, indexNo: number, splits: any[]) => void;
    originalAmount: number;
    glDebitAccounts: any[];
    creditAccountName: string;
    creditAccountId: string;
    transactionAt: string;
    description: string;
    splitData: any[];
    indexNo: number;
    filterDebitAccountList: (inputValue: string) => Promise<any[]>; // Updated type
}

const SplitModal: React.FC<SplitModalProps> = ({
    rowID, show, onHide, onSave, originalAmount, glDebitAccounts, creditAccountName, creditAccountId, transactionAt, description, splitData, indexNo, filterDebitAccountList
}) => {
    const { handleSubmit, control, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            splits: [{ amount: '', debitAccount: '' }]
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'splits'
    });

    const [remainingAmount, setRemainingAmount] = useState(originalAmount);

    const splits = useWatch({ control, name: 'splits' });

    useEffect(() => {
        const totalAmount = splits.reduce((sum, split) => sum + parseFloat(split.amount || '0'), 0);
        setRemainingAmount(parseFloat((originalAmount - totalAmount).toFixed(2)));
    }, [splits, originalAmount]);

    useEffect(() => {
        if (show) {
            reset({ splits: [] });

            const existingSplits = splitData.filter(data => data.rowID === rowID);
            if (existingSplits.length > 0) {
                existingSplits.forEach(data => {
                    append({
                        amount: data.deposit,
                        debitAccount: data.deposit_debit_account_id
                    });
                });
            } else {
                append({ amount: '', debitAccount: '' });
            }
        }
    }, [show, splitData, reset, append, rowID]);

    const onSubmit = (data: any) => {
        const totalAmount = data.splits.reduce((sum: number, split: any) => sum + parseFloat(split.amount || '0'), 0);
        if (parseFloat(totalAmount.toFixed(2)) === parseFloat(originalAmount.toFixed(2))) {
            const formattedSplits = data.splits.map((split: any) => ({
                id: rowID,
                deposit: parseFloat(split.amount),
                deposit_debit_account_id: split.debitAccount,
                deposit_credit_account_id: creditAccountId,
                rowID: rowID,
            }));
            onSave(rowID, indexNo, formattedSplits);
            reset();
            onHide();
        } else {
            toast.error("The total split amount must match the original amount.");
        }
    };

    const handleClose = () => {
        reset();
        onHide();
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Split Amount</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td><b>Date:</b></td>
                                <td>{moment(transactionAt).format('MM-DD-YYYY h:mm:ss')}</td>
                            </tr>
                            <tr>
                                <td><b>Description:</b></td>
                                <td className="text-start text-truncate" style={{ maxWidth: '150px' }} title={description || 'NA'}>
                                    {description || 'NA'}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Amount:</b></td>
                                <td>{currencyFormat(originalAmount)}</td>
                            </tr>
                            <tr>
                                <td><b>Credit GL Account:</b></td>
                                <td>{creditAccountName}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Amount</th>
                                <th>Debit GL Account</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fields.map((item, index) => (
                                <tr key={item.id}>
                                    <td>
                                        <Controller
                                            name={`splits.${index}.amount`}
                                            control={control}
                                            rules={{ required: 'Amount is required', min: { value: 0, message: 'Amount must be positive' } }}
                                            render={({ field }) => (
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter amount"
                                                    {...field}
                                                    isInvalid={!!errors.splits?.[index]?.amount}
                                                />
                                            )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.splits?.[index]?.amount?.message}
                                        </Form.Control.Feedback>
                                    </td>
                                    <td>
                                        <Controller
                                            name={`splits.${index}.debitAccount`}
                                            control={control}
                                            rules={{ required: 'Debit account is required' }}
                                            render={({ field }) => (
                                                <AsyncSelect
                                                    {...field}
                                                    loadOptions={filterDebitAccountList} // Used async loadOptions
                                                    defaultOptions={glDebitAccounts} // Default options
                                                    isSearchable
                                                    placeholder="Select Debit Account"
                                                    className={errors.splits?.[index]?.debitAccount ? 'is-invalid' : ''}
                                                />
                                            )}
                                        />
                                        <div className="invalid-feedback d-block">
                                            {errors.splits?.[index]?.debitAccount?.message}
                                        </div>
                                    </td>
                                    <td>
                                        {fields.length > 1 && (
                                            <Button variant="danger" onClick={() => remove(index)} className="me-2">
                                                <span role="img" aria-label="Remove">-</span>
                                            </Button>
                                        )}
                                        <Button variant="secondary" onClick={() => append({ amount: '', debitAccount: '' })}>
                                            <span role="img" aria-label="Add">+</span>
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="mt-3">
                        <b>Transaction ATM:</b> {currencyFormat(originalAmount.toFixed(2))}<br />
                        <b>Remaining Split Total:</b> <span style={{ color: remainingAmount < 0 ? 'red' : 'black' }}>{currencyFormat(remainingAmount.toFixed(2))}</span>
                    </div>
                    
                    <div className="d-flex justify-content-end mt-3">
                        <Button type="submit" variant="primary" className="me-2">Split & Submit</Button>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SplitModal;
