import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function useSettingsApi() {

    const callApi = useApiCall();

    const fetchSettingsData = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SETTINGS.FETCH_DETAILS, data, onSuccess, onError);
    }
    const updateAccountingSettings = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SETTINGS.UPDATE_ACCOUNTING, data, onSuccess, onError);
    }


    return {
        fetchSettingsData,
        updateAccountingSettings
    }
}