import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getMappedAcc } from '../../store/user/selectors'

export function useAccountMapped() {

  const mappedAcc = useSelector(getMappedAcc);

  const mappedAccChecker = () => {
    return mappedAcc;
  }
  const [mappedAccDetails, setMappedAccDetails] = useState(mappedAccChecker())

  useEffect(() => {
    setMappedAccDetails(mappedAccChecker())
  }, [mappedAcc]);

  return mappedAccDetails
}