import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function transTableDataApi() {

    const callApi = useApiCall();

    const fetchBankTransData = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.TRANSACTION.TRANS_LIST, data, onSuccess, onError);
    }


    return {
        fetchBankTransData
    }
}